import "./App.css";
import { PrimaryBtn, RColumn, RRow, SecondaryBtn, Space } from "./comps/otherCOmps";
import { Text, Title1, Title2 } from "./comps/textComps";
import char1Img from "./assets/1-t.png";
import char2Img from "./assets/2-t.png";
import char3Img from "./assets/3-t.png";
import char4Img from "./assets/4-t.png";
import char5Img from "./assets/5-t.png";

import char1Imgb from "./assets/1-b.png";
import char2Imgb from "./assets/2-b.png";
import char3Imgb from "./assets/3-b.png";
import char4Imgb from "./assets/4-b.png";
import char5Imgb from "./assets/5-b.png";
import { useEffect, useState } from "react";
import { connectMetaMask, mint, mintWeap } from "./bc";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import w1 from "./assets/0.gif";
import w2 from "./assets/1.gif";
import w3 from "./assets/2.gif";

import logoImg from "./assets/logo.svg";


function App() {

  const priceNfts = [0.01, 0.012, 0.015, 0.02, 0.03];
  const nameNfts = ["Soldier", "Lady", "Macho", "Archer", "Lady elf"];
  const imgNfts = [char3Img, char1Img, char5Img, char4Img, char2Img];
  const imgNftsb = [char3Imgb, char1Imgb, char5Imgb, char4Imgb, char2Imgb];

  const pirceWeap = [0.01, 0.01, 0.01];
  const nameWep = ["Blade of the Eternal Dawn", "Shadowstrike Saber", "Sakura Shogun Katana" ]
  const imgWep = [w1, w2, w3];
  const weapTraits = [
    [
      {
          "trait_type": "Damage",
          "value": 28
      },
      {
          "trait_type": "Speed",
          "value": 14
      },
      {
          "trait_type": "Elemental Affinity",
          "value": 65
      }
  ],
  [
    {
        "trait_type": "Damage",
        "value": 26
    },
    {
        "trait_type": "Speed",
        "value": 28
    },
    {
        "trait_type": "Elemental Affinity",
        "value": 72
    }
  ],
    [
      {
          "trait_type": "Damage",
          "value": 38
      },
      {
          "trait_type": "Speed",
          "value": 22
      },
      {
          "trait_type": "Elemental Affinity",
          "value": 59
      }
  ],
  ]
  const [walletUser, setWallerUser] = useState();

  const connectMeta = async () => {
    const wallet = await connectMetaMask();
    setWallerUser(wallet);
  }

  const mintNft = async (id) => {
    if(!walletUser){
      toast.error("Connect Metamask first!", {
        position: "top-right",
        autoClose: 5000, // Millisecondi prima che la notifica si chiuda automaticamente
        hideProgressBar: false,
      });
      return;
    }
    try{
    const res = await mint(id, walletUser);
    console.log(res);

    toast.success("Minted! Check your wallet", {
      position: "top-right",
      autoClose: 5000, // Millisecondi prima che la notifica si chiuda automaticamente
      hideProgressBar: false,
    });
    }catch(e){

    }
  
  }
  const mintNftWeap = async (id) => {
    if(!walletUser){
      toast.error("Connect Metamask first!", {
        position: "top-right",
        autoClose: 5000, // Millisecondi prima che la notifica si chiuda automaticamente
        hideProgressBar: false,
      });
      return;
    }
    try{
    const res = await mintWeap(id, walletUser);
    console.log(res);

    toast.success("Minted! Check your wallet", {
      position: "top-right",
      autoClose: 5000, // Millisecondi prima che la notifica si chiuda automaticamente
      hideProgressBar: false,
    });
    }catch(e){

    }
  
  }
  useEffect(() => {
    if(!window.ethereum) return;
    window.ethereum.on('chainChanged', (_chainId) => {
      window.location.reload();
    });
  }, []);

 
  function SingleNft({index}){
    return(
      <>
      <div style={{width: "200px"}} className="nftCont">
        <RColumn>
          <img src={imgNfts[index]} height="300px" alt={nameNfts[index]} className="nftImg"/>
          <img src={imgNftsb[index]} height="300px" alt={nameNfts[index]} className="nftImgB" style={{borderRadius: "12px"}}/>
          <Space/>
          <div className="NftTit">{nameNfts[index]}</div>
          <Space/>
          <SecondaryBtn onClick={() => mintNft(index)}>Mint {priceNfts[index]} ETH</SecondaryBtn>
        </RColumn>
        </div>
      </>
    )
  }
  function SingleWeap({index}){
    return(
      <>
      <div style={{width: "200px"}} className="nftCont">
        <RColumn>
          <img src={imgWep[index]} height="250px" alt={nameWep[index]} style={{borderRadius: "12px"}}/>
          <Space/>
          <Title2>{nameWep[index]}</Title2>
          <Space/>
          {weapTraits[index].map((e, i) => (
          <RRow horizontalAlign="space-between" width={"100%"}>
            <Text>{e.trait_type}</Text>
            <Text wight={"bold"} fontSize="15px" color="rgb(211, 97, 9)" >{e.value}</Text>
          </RRow>))}
          <Space/>
          <SecondaryBtn onClick={() => mintNftWeap(index)}>Mint {pirceWeap[index]} ETH</SecondaryBtn>
        </RColumn>
        </div>
      </>
    )
  }
  function SingleHow({tit, text}){
    return(
      <>
    
      <RColumn horizontalAlign="center" maxWidth={"400px"} padding={"0px 20px"}>
        <Title1 color="#C56829">{tit}</Title1>
        <Space/>
        <div style={{color:"#D0D0D0", fontSize: "20px", fontWeight: "600"}}>
        {text}
        </div>
      </RColumn>
      </>
    );
  }
  return (
   <>
    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />

   <div style={{backgroundColor: "#1A1A1A"}}>
    <RRow horizontalAlign="space-between"  padding="16px 100px">
      <img src={logoImg} height={"50px"}/>
      {!walletUser && 
      <PrimaryBtn onClick={connectMeta}>Connect MetaMask</PrimaryBtn>
      }
      {walletUser && 
      <Text fontSize="17px">
        {walletUser.slice(0, 5)}...{walletUser.slice(walletUser.length - 3, walletUser.length)}
      </Text>
      }
    </RRow>
    </div>
    <Space height="40px"/>
    <RColumn>
      <Title1 size="26px">Mint you Duel0x Character to play the game</Title1>
      <Space height="40px"/>
      
      <div style={{maxWidth: "1120px", color:"#D0D0D0", fontSize: "20px", fontWeight: "600"}}>
          Welcome to Duel0x, the ultimate gateway to a revolutionary gaming experience powered by NFTs! Dive into a world where every NFT you acquire is not just a collectible but a key to a unique gaming adventure. With each NFT purchase, you unlock a character that becomes your avatar in our immersive gaming universe.
      </div>
      <Space/>
     
      <div style={{display:"flex", flexWrap: "wrap", justifyContent: "space-between", width:"100%"}}>
        <div/>
        {Array.from({ length: 5 }, (_, i) => (
          <SingleNft index={i} key={i}/>
        ))}
      
        <div/>
      </div>
      <Space height="100px"/>
      <div style={{display:"flex", flexWrap: "wrap", justifyContent: "space-between", width:"100%"}}>
        <div/>
        {Array.from({ length: 3 }, (_, i) => (
          <SingleWeap index={i} key={i}/>
        ))}
        <div/>
      </div>
      <Space height="100px"/>
      <Title1>How it works</Title1>
      <Space height="60px"/>
      <RRow>
        <SingleHow tit={"Unlock"} text="Acquire exclusive NFTs to gain access to CryptoVerse Heroes."/>
        <SingleHow tit={"Mine"} text="Explore the vast digital landscapes and mine resources using your character's unique abilities."/>
        <SingleHow tit={"Play"} text="Engage in thrilling battles, solve challenges, and level up your character for even more exciting gameplay."/>
      </RRow>
      <Space height="120px"/>
      <div style={{color:"#D0D0D0", fontSize: "20px", fontWeight: "600", maxWidth: "1120px"}}>
      Embark on a journey like never before – where the blockchain meets gaming, and your NFTs come to life in CryptoVerse Heroes! Unlock, mine, play, and be part of the next frontier in gaming evolution.
      </div>

    </RColumn>

    <Space height="200px"/>
   </>
  );
}


export default App;
