import { toast } from 'react-toastify';
import Web3 from 'web3';
import { collectionChatAbi } from './collectionCharAbi';
import { collectionWeapAbi } from './collectionWeaponAbi';


const collectionAddress = "0x8C618dD96e1208cf43dEA3aC915093980602b244";
const collectionWeapnAddress = "0x7Ce0323c36530E49B4eF1fE6Fd7464c507C53cAe";
const prices = [
    10000000000000000n,
    12000000000000000n,
    15000000000000000n,
    20000000000000000n,
    30000000000000000n
];
const weapPrices = [
    10000000000000000n,
    10000000000000000n,
    10000000000000000n
];
export async function connectMetaMask(){
    if (window.ethereum) {
    const currentChainID = await window.ethereum.request({ method: 'eth_chainId' });
    console.log(currentChainID); 
  

    if(currentChainID !== "0x13881"){
        addChain();
        return;
    }
    const accounts =  await window.ethereum.request({ method: 'eth_requestAccounts' })
      const userAddress = accounts[0];
        return userAddress;
    } else {
        console.log('MetaMask non installato.');
        toast.error("Metamask not installed", {
            position: "top-right",
            autoClose: 5000, // Millisecondi prima che la notifica si chiuda automaticamente
            hideProgressBar: false,
          });
    }
}
export async function mint(id, account){
    const web3 = new Web3(window.ethereum);

    const contract_abi = collectionChatAbi;
    const contract = new web3.eth.Contract(contract_abi, collectionAddress);
    var response;
    try{ 
        response = await contract.methods.mint(id, 1).send({from: account, value: prices[id]});
    }
    catch (e){
    console.log("user denied transaction");
    toast.error("User denied transaction", {
        position: "top-right",
        autoClose: 5000, // Millisecondi prima che la notifica si chiuda automaticamente
        hideProgressBar: false,
        });
    throw e;
    }
    return response;
}
export async function mintWeap(id, account){
    const web3 = new Web3(window.ethereum);

    const contract_abi = collectionWeapAbi;
    const contract = new web3.eth.Contract(contract_abi, collectionWeapnAddress);
    var response;
    try{ 
        response = await contract.methods.mint(id, 1).send({from: account, value: weapPrices[id]});
    }
    catch (e){
    console.log("user denied transaction");
    toast.error("User denied transaction", {
        position: "top-right",
        autoClose: 5000, // Millisecondi prima che la notifica si chiuda automaticamente
        hideProgressBar: false,
        });
    throw e;
    }
    return response;
}
function addChain(){
    window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
            chainId: '0x13881', 
            chainName: 'Mumbai Testnet',
            nativeCurrency: {
                name: 'Matic',
                symbol: 'MATIC',
                decimals: 18
            },
            rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
            blockExplorerUrls: ['https://mumbai.polygonscan.com']
        }]
    })
    .catch((error) => {
    console.log(error)
    }) 
}